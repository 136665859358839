.DraftEditor-root {
  border: 1px solid #f1f1f1;
  padding: 20px;
  border-radius: 3px;
}

.rdw-image-modal {
  left: -215px;
}

.rdw-emoji-modal {
  left: -169px;
}

.rdw-embedded-modal {
  left: -124px;
}
