.portfolio-detail-wrapper {

  .portfolio-detail-description-wrapper {
    text-align: center;
    margin-top: 42px;
    display: flex;
    justify-content: center;

    .description {
      width: 700px;
      font-size: 2em;
      margin-bottom: 50px;
    }
  }

  .bottom-content-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .back-button, .expand-button {
      padding: 15px;
      background-color: #01455C;
      border-radius: 10px;
      text-decoration: none;
      color: white;
      transition: 0.5s ease-in-out;
      font-size: 1em;
      margin-right: 10px;
      width: fit-content;

      &:hover {
        background-color: white;
        color: #01455C;
      }
    }
  }
}
