.side-nav-wrapper {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px;
  background-color: black;
  padding-bottom: 10vh;
  height: 60vh;

  @media (max-width: 610px) {
    padding: 10px;
  }

  .nav-link-wrapper {
    display: flex;
    transition: 0.5s ease-in-out;
    font-size: 1.3rem;
    color: #ffffff;

    @media (max-width: 610px) {
      font-size: 1rem;
    }

    a {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #ffffff;
      text-decoration: none;

      span,
      svg {
        transition: color 0.5s ease-in-out;
      }

      &:hover {
        span,
        svg {
          color: #cc9900;
          fill: #cc9900; /* For SVG path */
        }
      }
    }

    .nav-link-active {
      color: #ffffff;
    }

    &:hover {
      color: #cc9900;
    }
  }

  .upper-side {
    padding-bottom: 5px;
  }

  .lower-side {
    margin-top: auto;
    display: flex;
    flex-direction: column;
  }

  .portfolio-link,
  .admin {
    margin-bottom: 10px;
    font-size: 1.1rem;

    @media (max-width: 610px) {
      font-size: 1rem;
    }
  }

  .icon-wrapper {
    margin-right: 5px;
  }

  .admin {
    align-items: center;
    color: $azul-galicia;
    cursor: pointer;

    a {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: $azul-galicia !important;

      span {
        margin-right: 5px;
      }
    }

    &:hover {
      transition: color 0.5s ease-in-out;
      color: #cc9900;
    }
  }
}
