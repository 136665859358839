.portfolio-contents-wrapper {
  display: grid;
  grid-template-columns: 3fr 1fr;
  background-color: #000000;
  margin-right: 5%;
  padding-top: 30px;
  background-repeat: no-repeat;
  //background-size: contain;
  background-position: flex-start;
  min-height: 60vh;

  .portfolio-contents-left-side {
    display: grid;
    grid-template-rows: auto 1fr;
    gap: 10px;
    //min-height: 60vh;

    .filter-links {
      display: grid;
      justify-items: flex-start;
      grid-gap: 10px;
      grid-template-columns: repeat(8, 1fr);
      justify-content: flex-start;

      .btn {
        width: 100%;
        background-color: #394e56;
        color: #999999;
        aspect-ratio: auto 90 / 25;
        background-size: contain;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        padding: 0;
        background-color: rgba(0, 0, 0, 0.5);

        span {
          font-size: small;
          font-weight: 500;
        }
      }

      /* Add a ring or border to the active button */
      .active {
        border-bottom: 1px solid gray;
      }

      @media (max-width: 2000px) {
        grid-template-columns: repeat(7, 1fr);
      }

      @media (max-width: 1600px) {
        grid-template-columns: repeat(6, 1fr);
      }

      @media (max-width: 1350px) {
        grid-template-columns: repeat(5, 1fr);
      }

      @media (max-width: 1095px) {
        grid-template-columns: repeat(4, 1fr);
      }

      @media (max-width: 960px) {
        grid-template-columns: repeat(3, 1fr);
      }

      @media (max-width: 712px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media (max-width: 388px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    .user-actions {
      display: flex;
      align-items: center;

      .MuiButton-label {
        display: flex;
        flex-direction: column;

        .MuiButton-endIcon {
          margin: 0px;
        }
      }

      .MuiIconButton-root {
        color: #da5726;
        margin-left: 15px;
        cursor: pointer;
        text-decoration: none;
        transition: 0.5s ease-in-out;
        font-size: 1.6rem;

        &:hover {
          color: #7f2200;
        }
      }
    }

    .portfolio-items-wrapper {
      display: grid;
      justify-items: flex-start;
      height: fit-content;
      grid-gap: 10px;
      grid-template-columns: repeat(8, 1fr);
      justify-content: flex-start;

      a {
        display: block; /* Ensure the anchor tag behaves like a block element */
        width: 100%; /* Full width of its container */
        height: 100%; /* Full height of its container */
        margin: 0; /* Reset margin */
        padding: 0; /* Reset padding */
      }

      p {
        color: white;
      }

      .portfolio-item-wrapper {
        position: relative;
        width: 100%;
        height: auto; /* Ensure it maintains aspect ratio */

        .portfolio-img-background {
          width: 100%;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          aspect-ratio: 90 / 75;
        }
      }

      @media (max-width: 2000px) {
        grid-template-columns: repeat(7, 1fr);
      }

      @media (max-width: 1600px) {
        grid-template-columns: repeat(6, 1fr);
      }

      @media (max-width: 1350px) {
        grid-template-columns: repeat(5, 1fr);
      }

      @media (max-width: 1095px) {
        grid-template-columns: repeat(4, 1fr);
      }

      @media (max-width: 960px) {
        grid-template-columns: repeat(3, 1fr);
      }

      @media (max-width: 712px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media (max-width: 388px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    .links-items-wrapper {
      display: flex;
      color: #999999;

      button {
        background-color: transparent;
        color: #999999;
        border: 0;
        font-size: 16px; /* Change the font size as needed */
        text-align: flex-start; /* Align text to the left */
        margin: 10px 0; /* Optional: Add some margin between list items */
      }

      a {
        color: #999999;
      }

      p {
        color: white;
      }

      .portfolio-item-wrapper {
        position: relative;
        width: 100%;
        height: auto; /* Ensure it maintains aspect ratio */

        .portfolio-img-background {
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          width: 100%;
          aspect-ratio: 90 / 75; /* Maintain the aspect ratio */
        }
      }
    }
  }

  .portfolio-contents-right-side {
    .category-side-title {
      padding-left: 5%;

      h2 {
        color: #999999;
        margin-top: 0;
      }
    }

    .category-side-description {
      padding-left: 5%;
      p {
        color: #999999;
      }
    }

    .Arquitecturas {
      p,
      h2 {
        color: #999999;
      }
    }

    .Dibujos {
      p,
      h2 {
        color: #cc9900;
      }
    }

    .Grabados {
      p,
      h2 {
        color: #996633;
      }
    }

    .Pinturas {
      p,
      h2,
      span {
        color: #ff0000;
      }
    }

    .Esculturas {
      p,
      h2 {
        color: #669999;
      }
    }

    .Diseños {
      p,
      h2 {
        color: #663399;
      }
    }

    .Fotografías {
      p,
      h2 {
        color: #6699ff;
      }
    }

    .Enseñanza {
      p,
      h2 {
        color: #669933;
      }
    }

    .Vídeos {
      p,
      h2 {
        color: #c13f00;
      }
    }

    .Enlaces {
      p,
      h2 {
        color: #999999;
      }
    }

    .Contacto {
      p,
      h2 {
        color: #999999;
      }
    }

    .Administrador {
      display: none;
    }
  }
}

.MuiSvgIcon-fontSizeLarge {
  color: white;
  font-size: larger !important;
}
