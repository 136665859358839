.MuiToolbar-root{
    display: flex;
    justify-content: space-between;
    margin: 0px 20px 0px 20px;
    color: #DA5726;

    .MuiSvgIcon-root{
        color: #DA5726;
    }

}

.MuiStep-root h1 {
    font-size: large !important;
}

.MuiPaper-root h1 {
    font-size: large !important;
}

.MuiTypography-root{
    font-family: 'Titillium Web', sans-serif !important;
}

.confirmation-message{
    font-size: medium;
}


// Main styles in the Checkout.js file
