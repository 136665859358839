.one-column {
  @include base-grid();
  grid-template-columns: 1fr;
  font-size: 1.1rem;
  height: 40px;
}

.two-column {
  @include base-grid();
  grid-template-columns: 1fr 1fr;
  height: 40px;
  font-size: 1.1rem;
}

.three-column {
  @include base-grid();
  grid-template-columns: 1.3fr 1.5fr 0.7fr;
  height: 40px;
  font-size: 1.1rem;
}
