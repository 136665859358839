.no-match-wrapper {
    padding-left: 20px;

    h2 {
        color: #999999;
    }

    a {
        color: #999999;
        font-size: 1.1rem;
    }
}