.product-container-styles {

  .user-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 5%;

    .cart-nav {
      display: flex;
      margin-left: 5%;
      margin-right: 5%;
      align-items: baseline;
    }

    
    .filter-links {
      display: flex;
      flex-wrap: wrap;
      justify-content: start;
      //align-items: center;
      margin-top: 1%;
      margin-bottom: 1.5%;
  
      .btn {
        border-color: whitesmoke;
        border-radius: 5px;
        margin: 2px 10px;
        padding: 10px;
        background-color: #f0f0f0e9;
        color: #000;
        transition:
          background-color 0.3s,
          color 0.3s;
        width: 150px;
  
        &.active {
          background-color: $azul-galicia;
          color: #fff;
        }

        @media (max-width: 1464px) {
          width: 130px;
          margin: 2px 8px;
        }

        @media (max-width: 1320px) {
          width: 110px;
          margin: 2px 4px;
        }


      }
    }

    .subcategory-filter-links {
      justify-content: start;
      .btn{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.8em;
        border-radius: 10px;
        margin: 1px 10px;
        padding: 10px;
        background-color: #f5f5f5c6;

        @media (max-width: 1664px) {
          width: 120px;
          margin: 2px 4px;
        }

        @media (max-width: 1420px) {
          width: 115px;
          padding: 8px;
        }
        @media (max-width: 1269px) {
          width: 110px;
          margin: 2px 3px;
          padding: 7px;
        }

      }
    }
    
    .isSmallScreen {
      display: block;
      margin: 20px 0;

      .filtering-button {
          color: white;
          font-size: 0.8em;
        }

        .selected-filter {
          background-color:#FF5733 !important;
        }

    }

  }

}

@media (min-width: 1091px) {
  .user-actions {
    .isSmallScreen {
      display: none !important;
    }

    .filter-links {
      display: flex;
      justify-content: space-around;
      margin: 20px 0;
      
    }
  }
}

@media (max-width: 1090px) {
  .user-actions {
    .filter-links {
      display: none !important;
    }
    .categories-title {
      display: none !important;
    }

    .isSmallScreen {
      display: block;
      margin: 20px 0;
    }
  }
}

