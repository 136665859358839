.MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -14px) scale(0.75) !important;
}

element.style {
    display: grid !important;
}

.signincard, .signupcard {


    .MuiInputBase-input:invalid, 
    .MuiInputBase-input,
    .MuiInputBase-input:focus,
    .MuiOutlinedInput-input {
        background-color: white !important;
        color: black !important;
    }

    .MuiIconButton-label {
        color: white !important;
    }

}


.MuiGrid-container {
    display: grid !important;
    align-items: center !important;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink,
.MuiTypography-colorTextSecondary, 
.MuiTypography-h5, 
.MuiGrid-item a, 
.MuiFormControlLabel-label {
    color: white !important;
}

.MuiAvatar-colorDefault,
.MuiButton-root {
    background-color: #019ACE !important;
}

//font-family: 'Titillium Web', sans-serif !important;

