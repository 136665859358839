.ecommerce-form-wrapper {
    @include base-grid();
    padding: 21px;
  
    @media (max-width: 1096px) {
      padding: 18px;
    }
  
    @media (max-width: 968px) {
      padding: 15px;
    }
  
    @media (max-width: 782px) {
      padding: 14px;
    }
  
    @media (max-width: 610px) {
      padding: 10px;
    }
  
    @include input-element();
    input {
      margin-bottom: 0px;
    }
  
    .image-uploaders {
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-direction: row;
  
      @media (max-width: 1155px) {
        flex-direction: column;
      }
  
      .filepicker {
        min-width: 150px;
        height: 180px;
        margin-bottom: 10px;
        
        @media (max-width: 1138px) {
          min-width: 200px;
          flex-direction: column;
        }
      }

      .gestion-portfolio-image-wrapper {
        img {
          width: 200px;
          height: 120px;
          object-fit: cover;
          border-radius: 5px;
        }
  
        .image-removal-link {
          display: flex;
          justify-content: center;
          a {
            font-weight: 900;
            color: $warning;
            cursor: pointer;
          }
        }
  
      }
    }
  
    .form-buttons {
      display: flex;
      justify-content: center;
      align-items: center;
  
      .logout-button {
        cursor: pointer;
      }
      
    }
  
    .btn {
      margin-right: 10px;
    }

    .three-column {
      display: grid;
      grid-template-columns: 1fr 1fr auto;
      gap: 10px; /* Add some gap between the columns */
      align-items: center; /* Align items vertically */
    }
    
    /* Styling for the checkbox and label */
    .checkbox-wrapper {
      display: flex;
      align-items: center; /* Align checkbox and label vertically */
    }
    
    /* Optional: Adjust spacing between checkbox and label */
    .checkbox-wrapper input[type="checkbox"] {
      margin-right: 5px; /* Space between checkbox and label */
    }

  }