.upper-side-nav-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 5%;
    padding-right: 5%;
    margin-top: 2vh;
    margin-bottom: 2vh;
    flex-wrap: wrap;
  
    .upper-nav-left-side {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
  
      .logo-image {
        padding-right: 5%;
      }
  
      .left-side {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        height: 30px;
        width: 30px;
        margin-right: 10px;
        cursor: pointer;
        transition: border 0.3s ease-in-out; /* Smooth transition for the ring */
      }
  
      .left-side.active-language {
        border: 2px solid whitesmoke; /* Customize the ring color */
        border-radius: 50%; /* Circular ring */
      }
  
      /* Media query for responsiveness */
      @media (max-width: 600px) {
        .left-side {
          height: 25px;
          width: 25px;
        }
      }
  
      @media (max-width: 400px) {
        .left-side {
          height: 20px;
          width: 20px;
        }
      }
  
      .logo-image {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        height: 50px;
        width: 40px;
        margin-right: 10px;
        margin-left: 35px;
      }
  
      /* Media query for responsiveness */
      @media (max-width: 600px) {
        .logo-image {
          height: 49.6px;
          width: 39.68px;
        }
      }
  
      @media (max-width: 400px) {
        .logo-image {
          height: 32.05px;
          width: 25.64px;
        }
      }
    }
  
    .upper-nav-right-side {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
  
      .right-side {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        height: 40px;
        width: 195px;
        margin-right: 1vw;
        cursor: pointer;
      }
  
      /* Media query for responsiveness */
      @media (max-width: 600px) {
        .right-side {
          height: 30.77px;
          width: 150px;
        }
      }
  
      @media (max-width: 400px) {
        .right-side {
          height: 20.51px;
          width: 100px;
        }
      }
    }
  }
  