.cart-nav-wrapper {
    display: flex;
    margin-left: 5%;
    margin-right: 5.8%;
    justify-content: flex-end;

    .icon-button {
        transition: transform 0.5s ease-in-out;
      }
      
      .icon-button:hover {
        //transform: scaleX(-1);
      
        svg {
          color: $azul-galicia;
        }
      }
}
