.portfolio-sidebar-list-wrapper {

  padding: 5px;
  color: white;

  .dropdown-wrapper {
    margin-bottom: 40px;
  }

  .portfolio-item-thumb {
    padding: 21px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: 610px) {
      padding: 16px;
    }
    

    .portfolio-thumb-img {
      height: 100px;

      @media (max-width: 610px) {
        height: 90px;
      }
      
    }

    .portfolio-thumb-img img {
      height: 100%;
      width: auto;
      
    }

    .text-content {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .title {
        color: $offwhite;
        font-size: 1.2em;
        padding-right: 10px;

        @media (max-width: 610px) {
          font-size: 1.1em;
          padding-right: 5px;
        }

      }

      .actions {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px;

        .action-icon {
          cursor: pointer;
          color: $offwhite;
          font-size: 1.2em;
          transition: 0.5s ease-in-out;

          @media (max-width: 610px) {
            font-size: 1.1em;
          }

          &:hover {
            color: $warning;
          }
        }
      }
    }
  }
}
