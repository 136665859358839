.zoom-in-modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto; /* Allow scrolling if content is too large */
}

.zoom-in-modal-content {
  position: relative;
  background-color: white;
  padding: 20px;
  max-width: 90%;
  max-height: 90%; /* Ensure modal content doesn't exceed 90% of viewport */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow: auto; /* If image is larger than the modal, allow scrolling */
}

.zoom-in-modal-image {
  max-width: 100%;
  height: auto;
  max-height: 100%;
  object-fit: contain; /* Ensures the full image is visible without distortion */
}

.zoom-in-close {
  position: absolute;
  top: 10px;
  right: 15px;
  color: #aaa;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.zoom-in-close:hover,
.zoom-in-close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
