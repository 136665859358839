@mixin base-btn {
  cursor: pointer;
  height: 42px;
  font-size: 1em;
  font-weight: 500;
  border: 1px solid transparent;
  transition: all 0.5s ease-in-out;
  background-color: black;
  color: whitesmoke;

  &:hover {
    background-color: $dark-teal;
  }

  &:active,
  &:focus {
    outline: none;
  }

}

@mixin input-element {
  input {
    font-family: "Titillium Web", sans-serif;
    color: $charcoal;
    padding: 5px 0px;
    width: 100%;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    border-bottom: 1px solid $teal;
    background-color: transparent;
    transition: 0.5s all ease-in-out;
    font-size: 1.1em;
    margin-bottom: 21px;

    &:focus {
      outline: none;
      border-bottom: 1px solid $dark-teal;
    }
  }
}

@mixin base-grid() {
  display: grid;
  grid-gap: 21px;
}
