.portfolio-form-wrapper {
  @include base-grid();
  padding: 21px;

  @media (max-width: 1096px) {
    padding: 18px;
  }

  @media (max-width: 968px) {
    padding: 15px;
  }

  @media (max-width: 782px) {
    padding: 14px;
  }

  @media (max-width: 610px) {
    padding: 10px;
  }

  @include input-element();
  input {
    margin-bottom: 0px;
  }

  .image-uploaders {
    @include base-grid();
    grid-template-columns: 200px 200px 200px;
    justify-content: center;

    @media (max-width: 1155px) {
      grid-template-columns: 180px 180px 180px;
    }

    @media (max-width: 1081px) {
      grid-template-columns: 150px 150px 150px;
    }

    @media (max-width: 919px) {
      grid-template-columns: 160px 160px;
    }

    @media (max-width: 719px) {
      grid-template-columns: 140px 140px;
    }

    @media (max-width: 659px) {
      grid-template-columns: 150px;
    }

    .gestion-portfolio-image-wrapper {
      img {
        width: 100%;
        height: 120px;
        object-fit: cover;
        border-radius: 5px;
      }

      .image-removal-link {
        display: flex;
        justify-content: center;
        a {
          font-weight: 900;
          color: $warning;
          cursor: pointer;
        }
      }

    }
  }

  .form-buttons {
    display: flex;
    justify-content: center;
    align-items: center;

    .logout-button {
      cursor: pointer;
    }
    
  }

  .btn {
    margin-right: 10px;
    border-radius: 5px;
  }

  .add-subcategory-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    background-color: mintcream;
    border: 1px solid #cbcbcb;
    border-radius: 5px;
  }

  .add-subcategory-section button {
    height: 40px;
    border: 1px solid #cbcbcb;
    border-radius: 5px;
    margin-bottom: 20px;
    font-weight: 500;
  }

  .subcategory-list {
    margin-top: 20px;
  }
  
  .subcategory-list ul {
    list-style-type: none;
    padding: 0;
  }
  
  .subcategory-list li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .btn-delete {
    cursor: pointer;
    padding: 5px 10px;
    border: none;
    background-color: red;
    color: white;
    border-radius: 4px;
  }
  

}
