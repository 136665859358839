.spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white; /* Color blanco para el icono y el texto */
  }
  
  .spinner span {
    margin-left: 10px;
    font-size: 18px; /* Tamaño del texto */
    color: white; /* Asegurarte de que el texto también sea blanco */
  }
  
  .spinner-icon {
    font-size: 24px; /* Tamaño del icono */
    color: white; /* Color blanco para el icono */
  }
  