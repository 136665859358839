
  /* Card styles */
  .MuiCard-root {
    border-radius: 10px;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }
  
  .MuiCard-root:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
  }
  
  .MuiCardContent-root {
    color: #fff;
    display: flex !important;
    flex-direction: column !important;
  }
  
  .MuiTypography-h5 {
    color: #fff;
  }
  
  .MuiTypography-body2 {
    color: #ccc;
  }
  
  .MuiIconButton-root {
    background-color: #019ACE;
    border-radius: 50%;
    padding: 20px;
    transition: background-color 0.3s ease;
  }
  
  .MuiIconButton-root:hover {
    background-color: #017BB5;
  }
  
  a {
    text-decoration: none;
  }
  