.centrado {
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 530px) {
    flex-direction: column;
  }

  .MuiInputLabel-outlined.MuiInputLabel-shrink,
  .MuiTypography-colorTextSecondary,
  .MuiTypography-h5,
  .MuiGrid-item a,
  .MuiFormControlLabel-label {
    color: black !important;
  }

  .MuiIconButton-label {
    background-color: white;
    color: grayscale($color: #464646);
  }

  .Mui-disabled {
    cursor: not-allowed !important;
    .MuiIconButton-label {
      color: gray;
    }
  }
}
